// @ts-check

const path = require("path");

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    defaultLocale: "no",
    locales: ["en", "no"]
  },
  fallbackLng: {
    default: ['no']
  },
  localePath: path.resolve("./public/locales"),
  react: { useSuspense: false },
  ns: [
    "common",
    "register",
    "adminRegister",
    "login",
    "overview",
    "passings",
    "edges",
    "labels",
    "labelGroups",
    "companies",
    "users",
    "notFound"
  ],
};
