import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { createContext, useEffect, useState } from 'react';
import { THEMES } from 'src/constants';
import { themeCreator } from './schemes';

export const ThemeContext = createContext((_themeName) => {});

const ThemeProviderWrapper = (props) => {
  const [themeName, setThemeName] = useState(THEMES.DEFAULT_THEME);

  useEffect(() => {
    const currentThemeName = global?.window?.localStorage?.getItem("appTheme") || "";
    if (currentThemeName.length > 0) setThemeName(currentThemeName);
  }, []);

  const handleSetTheme = (themeName = THEMES.DEFAULT_THEME) => {
    global?.window.localStorage.setItem('appTheme', themeName);
    setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={handleSetTheme}>
        <ThemeProvider theme={themeCreator(themeName)}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
