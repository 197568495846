import { PureLightTheme } from './PureLightTheme';
import { GreyGooseTheme } from './GreyGooseTheme';
import { PurpleFlowTheme } from './PurpleFlowTheme';
import { DefaultTheme } from './defaultTheme';
import { NordicMidnight } from './NordicMidnight';
import { NocturneTheme } from './NocturneTheme';
import { THEMES } from 'src/constants';

const themeMap = {
  PureLightTheme,
  GreyGooseTheme,
  PurpleFlowTheme,
  DefaultTheme,
  NordicMidnight,
  NocturneTheme
};

export function themeCreator(theme = THEMES.DEFAULT_THEME) {
  return themeMap[theme];
}
